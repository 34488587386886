<template>
  <a-modal
    ref="modal"
    :title="title"
    :width="modalWidth"
    style="text-align: center"
    :visible="visible"
    :footer="null"
    :keyboard="true"
    :destroyOnClose="true"
    @cancel="()=>{this.visible=false}"
  >
    <VideoPlayer
      v-show="true"
      :videoSrc="videoSrc"
      :videoPoster="videoPoster"
      :width="videoWidth"
      :height="videoHeight"
      :autoplay="false"
      :controls="true"
      :loop="false"
      :muted="false"
      preload="auto"
      :showPlay="true"
      :playWidth="96"
      zoom="contain">
    </VideoPlayer>
  </a-modal>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer'

export default {
  name: 'PlayVideo',
  components: {
    VideoPlayer
  },
  data () {
    return {
      visible: false,
      title: '',
      // 视频链接支持https或require本地地址
      videoSrc: null,
      // 视频封面支持https或require本地地址
      videoPoster: null,
      modalWidth: 848,
      videoWidth: 800,
      videoHeight: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.modalWidth = document.body.scrollWidth / 2
      this.videoWidth = document.body.offsetWidth / 2 - 48
      this.videoHeight = this.videoWidth / 16 * 9
    })
  },
  methods: {
    init () {
      this.visible = true
    },
    openForm (videoUrl, title) {
      this.init()
      this.title = title
      if (videoUrl) {
        this.videoSrc = videoUrl
      }
    }
  }
}
</script>

<style
  scoped>

</style>
